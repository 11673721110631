<template>
  <div>
    <!-- 步驟顯示 SR-->
    <div class="check-step bg-white d-flex shadow-sm">
      <div class="item">
        <span>01.</span>
        <span>確認商品</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item">
        <span>02.</span>
        <span>取貨方式</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item">
        <span>03.</span>
        <span>金流付款</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item finish active">
        <span>04.</span>
        <span>完成</span>
        <span class="material-icons-outlined"></span>
      </div>
    </div>
    <!-- 步驟顯示 END-->
    <div class="order-finish">
      <br />
      <h3 class="text-center mt-2 mb-3">訂單送出</h3>
      <div class="container">
        <p class="text-center pt-5">
          <img src="@/assets/images/icon-finished.svg" alt="訂單送出" />
        </p>
        <p class="text-center pb-5">
          訂單已送出，<span style="color: red">請等待店家確認後才表示訂單成立</span>，
          <br />詳細資訊請至「訂單列表」查詢。謝謝!
        </p>
        <div class="row row-cols-2 fix-bottom">
          <div class="col">
            <a href="/shop/category" class="btn btn-block btn-gray">再次下單</a>
          </div>
          <div class="col">
            <a href="/shop/orders" class="btn btn-block btn-main">查看訂單列表</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.check-step .item span:nth-child(2) {
  font-size: 0.94rem;
}
</style>
<script>
import { inject, reactive } from "vue";

export default {
  name: "CheckoutFinishView",
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    const shop_name = inject("shop_name");
    emit("onLoading", false);
    const state = reactive({});
    return { shop_name, state };
  },
};
</script>

<style lang="scss" scoped>
.fix-bottom {
  position: fixed;
  width: 100%;
  max-width: 480px;
  bottom: 16px;
}
</style>
